import React, { useState, useRef, useEffect } from 'react';
import throttle from 'lodash.throttle';
import PropTypes from 'prop-types';
import { PrevArrow, NextArrow } from './arrows/arrows';
import './Tabs.scss';
import classNames from 'classnames';

const Tabs = ({
  tabs,
  className,
  alignTabsLeft,
  scrollTab,
  disableTabs,
  startTab = 0,
  disableCloseTabs = false,
  hiddenText = '',
  showContentTab,
  setShowContent,
  setFastQuoteHeight,
  ...other
}) => {
  const [currentTab, setCurrentTab] = useState(startTab);
  const toggleTab = (e) => {
    const isAlreadySelected =
      e.currentTarget.parentNode.getAttribute('aria-selected');
    const index = parseInt(
      e.currentTarget.parentNode.getAttribute('data-index')
    );
    const fastQuote = document.getElementsByClassName('fast-quote')[0];
    if (fastQuote !== undefined) {
      setFastQuoteHeight(fastQuote?.clientHeight);
    }
    if (typeof isAlreadySelected === 'string') {
      if (isAlreadySelected !== 'false') {
        setShowContent(false);
        e.currentTarget.parentNode.setAttribute('aria-selected', 'false');
        e.currentTarget.parentNode.setAttribute(
          'data-tablabel-active',
          'false'
        );
      } else {
        setShowContent(true);
        setCurrentTab(index);
        e.currentTarget.parentNode.setAttribute('aria-selected', 'true');
        e.currentTarget.parentNode.setAttribute('data-tablabel-active', 'true');
      }
    } else {
      setShowContent(true);
      setCurrentTab(index);
    }
  };

  const sliderContainer = useRef(null);

  const [showArrowLeft, setshowArrowLeft] = useState(false);
  const [showArrowRight, setshowArrowRight] = useState(false);

  const scrollContainer = (direction) => {
    let amount = direction * (sliderContainer.current.clientWidth / 2);
    sliderContainer.current.scrollLeft += amount;
  };

  useEffect(() => {
    const windowListener = throttle(() => {
      // Show and hide right arrow with respect to the width of years and the width of the slider container
      let children = sliderContainer.current.children;
      let totalWidth = 0;

      for (var i = 0; i < children.length; i++) {
        let childrenStyle = window.getComputedStyle(children[i]);
        let childrenMarginRight = parseInt(
          childrenStyle.getPropertyValue('margin-right')
        );
        let childrenMarginLeft = parseInt(
          childrenStyle.getPropertyValue('margin-left')
        );
        totalWidth +=
          children[i].clientWidth + childrenMarginLeft + childrenMarginRight;
      }

      if (totalWidth > sliderContainer.current.clientWidth) {
        setshowArrowRight(true);
      } else {
        setshowArrowRight(false);
      }
    }, 250);

    const swipeListener = throttle(() => {
      // Hide right arrow because the scrollbar is at the end
      if (
        Math.ceil(
          sliderContainer.current.offsetWidth +
            sliderContainer.current.scrollLeft
        ) === sliderContainer.current.scrollWidth
      ) {
        setshowArrowRight(false);
      }

      // Show right arrow because the scrollbar is moving in left direction
      if (
        Math.ceil(
          sliderContainer.current.offsetWidth +
            sliderContainer.current.scrollLeft
        ) < sliderContainer.current.scrollWidth
      ) {
        setshowArrowRight(true);
      }

      // Hide left arrow because the scrollbar is at the begin
      if (sliderContainer.current.scrollLeft === 0) {
        setshowArrowLeft(false);
      }

      // Show left arrow because the scrollbar is moving in right direction
      if (sliderContainer.current.scrollLeft > 0) {
        setshowArrowLeft(true);
      }
    }, 250);

    const currentSlider = sliderContainer.current;
    currentSlider.addEventListener('scroll', swipeListener);
    window.addEventListener('resize', windowListener);
    windowListener();

    return () => {
      currentSlider.removeEventListener('scroll', swipeListener);
      window.removeEventListener('resize', windowListener);
    };
  }, []);

  return (
    <div className={className} {...other} data-molecule='tabs'>
      {showArrowLeft && (
        <PrevArrow
          className={classNames('arrow', {
            'show-arrow-left': showArrowLeft,
          })}
          onClick={() => scrollContainer(-1)}
        />
      )}
      <ul
        className={classNames(`tablabel-list`, {
          'tablabel-list--alignTabsLeft': alignTabsLeft,
          'tablabel-list--scrollTab': scrollTab,
          'tablabel-list--disabled': disableTabs,
        })}
        data-tablabel-list=''
        ref={sliderContainer}
        role='tablist'
      >
        {tabs?.map((tab, index) => (
          <li
            className='tablabel-list__item'
            key={index}
            data-tablabel-active={index === currentTab ? true : null}
            data-index={index}
            aria-selected={index === currentTab}
            role='tab'
          >
            <button className='tablabel-list__btn' onClick={toggleTab}>
              {tab?.content?.props?.formComponent?.props?.data?.promoValue
                ?.value && (
                <span className='tablabel-list__promo'>
                  <span className='tablabel-list__promo-text'>
                    {
                      tab?.content.props.formComponent.props.data.promoValue
                        .value
                    }
                  </span>
                </span>
              )}
              {tab?.label}
            </button>
          </li>
        ))}
      </ul>
      {showArrowRight && (
        <NextArrow
          className={classNames('arrow', {
            'show-arrow-right': showArrowRight,
          })}
          onClick={() => scrollContainer(1)}
        />
      )}

      {showContentTab || disableCloseTabs ? (
        <ul className='tabcontent-list' data-tabcontent-list role='tablist'>
          {tabs?.map((tab, index) => (
            <li
              className='tabcontent-list__item'
              key={index}
              data-tabcontent-active={index === currentTab ? true : null}
              aria-selected={index === currentTab}
              role='tab'
            >
              {tab?.content}
            </li>
          ))}
        </ul>
      ) : (
        <div className='tabcontent-list__hide'>{hiddenText}</div>
      )}
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      content: PropTypes.element.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
  startTab: PropTypes.number,
};

export default Tabs;
