import React from 'react';
import AnchorHooking from '../../molecules/anchor-hooking/AnchorHooking';
import Title from '../../molecules/title/title';
import { Wrapper } from '../../utils/components';
import { getCommonOrganismData } from '../../utils/functions';
import './FasciaTab.scss';
import Tabs from '../../molecules/Tabs';
import '../../molecules/Tabs.scss';
import classNames from 'classnames';
import ContenutoHtmlInner from '../../molecules/helpers-contenuto-html/ContenutoHtmlInner';
import withPreview from '../../utils/with-preview';

const FasciaTab = ({ data, considerMenuSpace }) => {
  const {
    htmlTagWrapper,
    backgroundColor,
    anchorLabel,
    marginTop,
    title,
    titleHeadingTag,
    titleIconId,
    titleIconThickness,
  } = getCommonOrganismData(data);
  const tabs = data?.description || [];
  const tabsToRender = tabs.map((tab) =>
    Object.assign({
      label: tab?.value,
      content: (
        <div className='box-editorial fascia-tab__content'>
          <ContenutoHtmlInner rawContent={tab?.content?.htmlContent?.value} />
        </div>
      ),
    })
  );
  return (
    <Wrapper
      tag={htmlTagWrapper}
      data-module-name='fascia-tab'
      data-module-code='C-58'
      className={classNames(`fascia-tab ${marginTop} wrapper`, {
        'fascia-tab--colored-background': backgroundColor !== 'transparent',
      })}
      style={{ '--bg': `var(--${backgroundColor})` }}
    >
      <AnchorHooking id={anchorLabel} belowMenu={considerMenuSpace} />
      {(title || titleIconId) && (
        <Title
          tag={titleHeadingTag}
          titleIconId={titleIconId}
          titleIconThickness={titleIconThickness}
        >
          {title}
        </Title>
      )}
      {
        <Tabs
          tabs={tabsToRender}
          className='fascia-tab__tabs'
          disableCloseTabs
          alignTabsLeft
          setShowContent={() => {}}
        ></Tabs>
      }
    </Wrapper>
  );
};

export default withPreview(FasciaTab);
